<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form" @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                          <b-row v-for="(detail, index) in formData.details" :key="index">
                            <b-col md="4" sm="6">
                              <ValidationProvider name="Name (En)" vid="name_en" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="name_en"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.name_en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name_en"
                                    :name="'details['+index+'][name]'"
                                    v-model="detail.name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="4" sm="6">
                              <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.name_bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name_bn"
                                    :name="'details['+index+'][name_bn]'"
                                    v-model="detail.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="4" sm="6">
                              <ValidationProvider name="Designation (En)" vid="designation_en" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="designation_en"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.designation_en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="designation_en"
                                    :name="'details['+index+'][designation_en]'"
                                    v-model="detail.designation_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="4" sm="6">
                              <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="designation_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.designation_bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="designation_bn"
                                    :name="'details['+index+'][designation_bn]'"
                                    v-model="detail.designation_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="6" sm="6">
                              <ValidationProvider name="Signature" vid="signature" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="attachment"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('globalTrans.signature')}} <span class="text-danger">*</span>
                                  </template>
                                  <input type="hidden" :name="'details['+index+'][pre_signature]'" v-model="detail.signature"/>
                                  <b-form-file
                                    :name="'details['+index+'][signature]'"
                                    accept=".png, .jpg, .jpeg"
                                    v-model="detail.signature"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  <a v-if="detail.signature" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + detail.signature" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                  <p class="text-danger">{{ $i18n.locale == 'en' ? '[Maximum 1 MB size signature and format png/jpg/jpeg]' : '[সর্বোচ্চ ১ এমবি সাইজ স্বাক্ষর এবং ফরমেট png/jpg/jpeg]' }}</p>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="2" sm="6" class="mt-5">
                                <b-button v-show="index == formData.details.length-1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i> {{ $t('globalTrans.add') }}</b-button>
                                <b-button v-show="index || ( !index && formData.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-col>
                          </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('signature')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { certificateSignatureStore, certificateSignatureView } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.item) {
      this.getEditingData()
    }
  },
  data () {
    return {
      loading: false,
      defaultValue: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
      formData: {
        circular_memo_no: this.item.circular_memo_no,
        details: [
          {
            name: '',
            name_bn: '',
            designation_en: '',
            designation_bn: '',
            signature: null,
            pre_signature: []
          }
        ]
      }
    }
  },
  computed: {
        // loadingState () {
        //   if (this.listReload) {
        //     return true
        //   } else if (this.loading) {
        //       return true
        //   } else {
        //     return false
        //   }
        // }
  },
  mounted () {
    core.index()
  },
  methods: {
    addItem () {
      const obj = {
        name: '',
        name_bn: '',
        designation_en: '',
        designation_bn: '',
        signature: null,
        pre_signature: []
      }
      const key1 = parseInt(this.formData.details.length - 1)
     const item = this.formData.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item.name_en === '' || item.name_bn === '' || item.designation_en === '' || item.designation_bn === '' || item.signature === null) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        if (this.formData.details.length < 3) {
          this.formData.details.push(obj)
        }
      }
    },
    remove (key) {
      this.formData.details.splice(key, 1)
    },
    async getEditingData () {
      this.loading = true
        const circularMemoNo = {
            circular_memo_no: this.item.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateSignatureView, circularMemoNo)
        if (!result.success) {
          this.loading = false
        } else {
          const courseList = result.data
          const atData = courseList.map(item => {
            const fileData = {}
            fileData.pre_signature = item.signature
            fileData.signature = item.signature
            return Object.assign({}, item, fileData)
          })
          this.formData.details = atData
          this.loading = false
        }
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
        if (key !== 'details') {
          formData.append(key, this.formData[key])
        }
      })

      result = await RestApi.postData(trainingElearningServiceBaseUrl, certificateSignatureStore, formData, config)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('signature')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
        this.loading = false
      }
      this.loading = false
    }
  }
}
</script>
